.ImageViewer {
	position: absolute;
	z-index: 12000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	.viewerbackground {
		position: absolute;
		z-index: 100;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-color: #000;
		background-color: rgba(0, 0, 0, 0.36);
	}

	.gallery-wrapper {
		position: absolute;
		z-index: 101;
		bottom: 0;
		left: 50%;

		display: flex;
		flex-direction: row;

		transform: translate(-50%);

		img {
			cursor: pointer;
		}

		img + img {
			margin-left: 12px;
		}

		.image {
			width: auto !important;
			height: 80px !important;
		}
	}

	.nav-button {
		position: absolute;
		top: 50%;

		cursor: pointer;
		transition: opacity 500ms ease-in-out;

		opacity: 0.6;
		color: #FFF;

		&:hover {
			opacity: 0.9;
		}

		&.left-button {
			left: -25px;
		}

		&.right-button {
			right: -25px;
		}
	}
}
