.agreement-calendar {
	flex: 1 1 auto;

	width: 100%;
	min-height: 2.71428571em;

	cursor: pointer;
	white-space: normal;

	outline: 0;

	line-height: 1em;

	.react-datetime-picker__wrapper {
		min-height: 2.71428571em;
		padding-left: 1em;

		cursor: pointer;
		transform: rotateZ(0deg);
		white-space: normal;

		color: rgba(0, 0, 0, 0.87);
		border: 1px solid rgba(34, 36, 38, 0.15);
		border-radius: 0.28571429rem;
		outline: 0;
		background: #FFF;
		box-shadow: none;

		line-height: 1em;

		&:hover {
			border-color: rgba(34, 36, 38, 0.35);
			box-shadow: none;
		}
	}

	.react-datetime-picker__inputGroup__input {
		position: relative !important;

		box-sizing: content-box !important;
		min-width: 0.54em !important;
		height: calc(100% - 2px) !important;
		padding: 3px !important;

		border: 0 !important;
		background: none !important;

		font: inherit !important;
		-moz-appearance: textfield !important;
	}

	.react-datetime-picker__inputGroup__divider {
		display: inline-block;

		box-sizing: border-box !important;
		width: 8px;
		margin-top: 12px;
		padding: 1px !important;

		white-space: pre !important;
	}

	.react-datetime-picker__button {
		padding: 4px 6px !important;

		border: 0 !important;
		background: transparent !important;
	}

	.react-datetime-picker__inputGroup__input--hasLeadingZero {
		margin-left: -0.54em !important;
		padding-left: calc(1px + 0.54em) !important;
	}
}

.fc-view {
	z-index: auto;
}

.completedmodal {
	width: 550px;
}
