.MainMenu {
	.right.menu {
		background-color: #ECECEC;

		.item {
			display: none;

			&::before {
				display: none;
			}
		}
	}
}
