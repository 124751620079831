.search-result-table-row {
	cursor: pointer;
}

.rental_agreement_text {
	textarea {
		min-height: 300px;
	}
}

//Tabs
.react-tabs__tab {
	&.isTabValid {
		color: #FFF;
		border: 1px solid;
		border-radius: 5px 5px 0 0;
		background-color: #21BA45;
	}

	&.isTabInvalid {
		color: #FFF;
		border: 1px solid;
		border-radius: 5px 5px 0 0;
		background-color: #DB2828;
	}
}

.dropzone {
	display: flex;
	align-items: center;
	flex: 1;
	flex-direction: column;

	padding: 20px;

	transition: border 0.24s ease-in-out;

	color: #BDBDBD;
	border-width: 2px;
	border-style: dashed;
	border-color: #EEE;
	border-radius: 2px;
	outline: none;
	background-color: #FAFAFA;
}


/* Form */
.agreement_fuel_in {
	button {
		cursor: default !important;

		&:focus,
		&:hover {
			background: #E0E1E2 !important;

			&.active {
				background: #C0C1C2 !important;
			}
		}
	}
}

.inline-field-with-column {
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding-top: 23px;

	&.no-padding {
		padding-top: 0;
	}

	h4 {
		padding-left: 0.5em;
	}
}

.full-cost-wrapper {
	display: flex;
	justify-content: space-between;

	.full-cost {
		width: 25%;

		font-weight: bold;
	}
}

.no-driver {
	.default.text {
		color: black !important;
	}
}

.sing-by-email-container {
	input {
		width: 250px;
	}
}
