.UserItem {
	display: flex;
	align-items: center;

	height: 100%;

	.avatar {
		margin-right: 20px;
	}

	.info {
		display: flex;
		align-items: end;
		flex-direction: column;

		div {
			margin-bottom: 2px;
		}
	}
}
