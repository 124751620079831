
.title {
	display: flex;
	flex-direction: column;
}

.download {
	text-decoration: underline;
	cursor: pointer;
}

.backdrop {
  opacity: 0.5;
}
