.Vehicles {
	.user-list {
		a.item {
			display: flex;
			align-items: center;

			i.user {
				margin-left: 4px !important;
			}
		}
	}

	.inline {
		&.fields {
			.field:last-child {
				padding-right: 0 !important;
			}
		}

		&.field {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.ui {
			&.input {
				width: 70% !important;
			}
		}
	}

	.image-upload-container {
		margin-top: 10px;

		.vehicle_gallery {
			padding-top: 20px;

			text-align: center;
		}

		&.isGalleryAddMode {
			display: none;
		}
	}

	.gallery-preview-container {
		margin-top: 10px;
		margin-bottom: 15px;

		.card {
			background: transparent !important;
			box-shadow: none !important;
		}
	}

	.search-input {
		input {
			padding-right: 2.67142857em !important;
		}
	}
}
