.Clients {
	.user-list {
		a.item {
			display: flex;
			align-items: center;

			i.user {
				margin-left: 4px !important;
			}
		}
	}

	.load-image-wrapper {
		position: relative;

		min-height: 250px;
		max-height: 250px;

		.placeholder {
			position: relative;
			z-index: 2;

			min-height: 250px;
			max-height: 250px;
		}

		.image-wrapper {
			position: absolute;

			width: 100%;
			min-height: 250px;
			max-height: 250px;

			background-size: contain;

			img {
				z-index: -1;

				width: 100%;
				height: 100%;
				min-height: 250px;
				max-height: 250px;

				transition: all 0.2s linear;
			}
		}

		.image-load-buttons-wrapper {
			position: absolute;
			bottom: 10px;

			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: center;

			width: 100%;
			margin-top: 10px;

			transition: opacity 0.2s linear;

			opacity: 0;
		}

		&:hover {
			.image-load-buttons-wrapper {
				opacity: 1;
			}
		}

		.field {
			margin: 0 !important;
		}
	}

	.inline {
		&.fields {
			.field:last-child {
				padding-right: 0 !important;
			}
		}

		&.field {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.ui {
			&.input {
				width: 70% !important;
			}
		}
	}

	.client-main-info-wrapper {
		.field {
			margin-bottom: 0.3em !important;

			input {
				text-align: right;
			}
		}
	}

	.search-input {
		input {
			padding-right: 2.67142857em !important;
		}
	}
}
