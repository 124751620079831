.react-calendar-timeline {
	.time-line-header {
		background: #2185D0 !important;

		.sidebar-header {
			display: flex;
			align-items: center;
			justify-content: center;

			color: #FFF;
		}
	}

	.rct-sidebar {
		.rct-sidebar-row {
			&.rct-sidebar-row-even {
				padding-left: 10px;
			}
		}
	}
}

.resource-view {
	background-color: #ECECEC !important;
}

.rent-planner {
	#RBS-Scheduler-root {
		.ant-row-flex {
			&.ant-row-flex-space-between {
				&.ant-row-flex-middle {
					display: none;
				}
			}
		}
	}

	.tool-bar {
		#RBS-Scheduler-root {
			width: 986px !important;

			margin: 0;

			.ant-row-flex {
				&.ant-row-flex-space-between {
					&.ant-row-flex-middle {
						display: flex;

						margin: 0 !important;
					}
				}
			}

			tbody {
				display: none;
			}
		}
	}
}
