.searchResultsTable {
	table-layout: fixed;
}

th {
	&:last-child {
		width: 10px;
		padding: 0 !important;
	}
}

.dataTableContainer {
	overflow-y: scroll;

	max-height: 300px !important;
}
