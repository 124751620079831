.ani-icon-wrapper {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(85, 85, 85, 0.5);
	transition: all 300ms ease-in-out;
	z-index: 100;
}

.ani-icon {
	width: 200px;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;

	border-radius: 50%;
	-webkit-animation: fall-in 0.75s;
	-moz-animation: fall-in 0.75s;
	-o-animation: fall-in 0.75s;
	animation: fall-in 0.75s;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	z-index: 5;
}

.ani-icon.success-icon {
	background: #21BA45;
}

.ani-icon.decline-icon {
	background: #DB2828;
}

.ani-icon.decline-icon > i {
	padding-top: 47px;
	padding-left: 5px;
}

.ani-icon > i {
	font-size: 8em !important;
	color: #FFF;
	padding-top: 47px;
	padding-left: 14px;
}

@-webkit-keyframes fall-in {
	0% {
		-ms-transform: scale(3, 3);
		-webkit-transform: scale(3, 3);
		transform: scale(3, 3);
		opacity: 0;
	}

	50% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
		opacity: 1;
	}

	60% {
		-ms-transform: scale(1.1, 1.1);
		-webkit-transform: scale(1.1, 1.1);
		transform: scale(1.1, 1.1);
	}

	100% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}

@-moz-keyframes fall-in {
	0% {
		-ms-transform: scale(3, 3);
		-webkit-transform: scale(3, 3);
		transform: scale(3, 3);
		opacity: 0;
	}

	50% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
		opacity: 1;
	}

	60% {
		-ms-transform: scale(1.1, 1.1);
		-webkit-transform: scale(1.1, 1.1);
		transform: scale(1.1, 1.1);
	}

	100% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}

@-o-keyframes fall-in {
	0% {
		-ms-transform: scale(3, 3);
		-webkit-transform: scale(3, 3);
		transform: scale(3, 3);
		opacity: 0;
	}

	50% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
		opacity: 1;
	}

	60% {
		-ms-transform: scale(1.1, 1.1);
		-webkit-transform: scale(1.1, 1.1);
		transform: scale(1.1, 1.1);
	}

	100% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}

@-webkit-keyframes plunge {
	0% {
		margin-top: -100%;
	}

	100% {
		margin-top: 25%;
	}
}

@-moz-keyframes plunge {
	0% {
		margin-top: -100%;
	}

	100% {
		margin-top: 25%;
	}
}

@-o-keyframes plunge {
	0% {
		margin-top: -100%;
	}

	100% {
		margin-top: 25%;
	}
}

@-moz-keyframes fall-in {
	0% {
		-ms-transform: scale(3, 3);
		-webkit-transform: scale(3, 3);
		transform: scale(3, 3);
		opacity: 0;
	}

	50% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
		opacity: 1;
	}

	60% {
		-ms-transform: scale(1.1, 1.1);
		-webkit-transform: scale(1.1, 1.1);
		transform: scale(1.1, 1.1);
	}

	100% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}

@-webkit-keyframes fall-in {
	0% {
		-ms-transform: scale(3, 3);
		-webkit-transform: scale(3, 3);
		transform: scale(3, 3);
		opacity: 0;
	}

	50% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
		opacity: 1;
	}

	60% {
		-ms-transform: scale(1.1, 1.1);
		-webkit-transform: scale(1.1, 1.1);
		transform: scale(1.1, 1.1);
	}

	100% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}

@-o-keyframes fall-in {
	0% {
		-ms-transform: scale(3, 3);
		-webkit-transform: scale(3, 3);
		transform: scale(3, 3);
		opacity: 0;
	}

	50% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
		opacity: 1;
	}

	60% {
		-ms-transform: scale(1.1, 1.1);
		-webkit-transform: scale(1.1, 1.1);
		transform: scale(1.1, 1.1);
	}

	100% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}

@keyframes fall-in {
	0% {
		-ms-transform: scale(3, 3);
		-webkit-transform: scale(3, 3);
		transform: scale(3, 3);
		opacity: 0;
	}

	50% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
		opacity: 1;
	}

	60% {
		-ms-transform: scale(1.1, 1.1);
		-webkit-transform: scale(1.1, 1.1);
		transform: scale(1.1, 1.1);
	}

	100% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}

@-moz-keyframes plunge {
	0% {
		margin-top: -100%;
	}

	100% {
		margin-top: 15%;
	}
}

@-webkit-keyframes plunge {
	0% {
		margin-top: -100%;
	}

	100% {
		margin-top: 15%;
	}
}

@-o-keyframes plunge {
	0% {
		margin-top: -100%;
	}

	100% {
		margin-top: 15%;
	}
}

@keyframes plunge {
	0% {
		margin-top: -100%;
	}

	100% {
		margin-top: 15%;
	}
}
