.SideBarList {
	a {
		padding-right: 10px !important;
		padding-left: 10px !important;
	}

	&.withScrollBars {
		overflow-y: scroll;

		max-height: 500px;
	}
}
