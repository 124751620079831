.catalog-tabs {
	.react-tabs {
		min-height: 33px;
		padding: 5px;

		background-color: #ECECEC;

		.react-tabs__tab {
			margin-right: 1px;
			padding: 1px 12px;

			vertical-align: bottom;

			border: 1px solid #AAA;
			border-radius: 3px;
			background-color: #FFF;

			&:focus {
				outline: none;
				box-shadow: none;
			}

			&::after {
				display: none;
			}

			&.react-tabs__tab--selected {
				z-index: 0;

				padding: 6px 12px;

				color: #565656;
				border-bottom: none;
				border-radius: 5px 5px 0 0;

				&::before {
					position: absolute;
					z-index: -1;
					top: 50%;
					right: 50%;
					left: 50%;

					width: 95%;
					height: 90%;

					content: "";
					transform: translate(-50%, -45%);

					border: 1px solid #B3B3B3;
					background: #ECECEC;
				}

				&:focus {
					outline: none;
					box-shadow: none;
				}

				&::after {
					display: none;
				}
			}
		}

		.react-tabs__tab-list {
			margin-bottom: 0;
		}

		.tab-wrapper {
			transition: none;

			&.react-tabs__tab-panel--selected {
				padding: 4px;

				border-right: 1px solid #AAA;
				border-bottom: 1px solid #AAA;
				border-left: 1px solid #AAA;
				background-color: #FFF;
			}

			.tab-wrapper-inner {
				padding: 8px 50px 8px 8px;

				border: 1px solid #B3B3B3;
				border-radius: 3px;
				background-color: #EBEBEB;

				label {
					color: #565656;
				}

				.inline {
					&.field {
						margin-bottom: 10px !important;
					}

					label {
						width: 50%;

						color: #565656;
					}

					input {
						width: 40%;
						padding: 3px;

						text-align: center;

						border: 1px solid #CCC9C9;
					}
				}

				.fuel-buttons {
					display: flex;
					justify-content: flex-end;

					padding-right: 9px;

					.buttons {
						button {
							padding-right: 1.1em;
							padding-left: 1.1em;
						}
					}
				}

				&.equal-padding {
					padding: 8px;
				}

				.dropdown {
					width: 70% !important;
					min-height: 2em;
					padding: 6px;

					text-align: center;

					border: 2px solid #CCC9C9 !important;

					.icon {
						top: 0.5em;

						width: auto !important;

						border: none !important;
					}
				}
			}
		}
	}

	.user-list {
		a.item {
			display: flex;
			align-items: center;

			i.user {
				margin-left: 4px !important;
			}
		}
	}

	.load-image-wrapper {
		position: relative;

		min-height: 250px;
		max-height: 250px;

		.placeholder {
			position: relative;
			z-index: 2;

			min-height: 250px;
			max-height: 250px;
		}

		.image-wrapper {
			position: absolute;

			width: 100%;
			min-height: 250px;
			max-height: 250px;

			background-size: contain;

			img {
				z-index: -1;

				width: 100%;
				height: 100%;
				min-height: 250px;
				max-height: 250px;

				transition: all 0.2s linear;
			}
		}

		.image-load-buttons-wrapper {
			position: absolute;
			bottom: 10px;

			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: center;

			width: 100%;
			margin-top: 10px;

			transition: opacity 0.2s linear;

			opacity: 0;
		}

		&:hover {
			.image-load-buttons-wrapper {
				opacity: 1;
			}
		}

		.field {
			margin: 0 !important;
		}
	}

	.inline {
		&.fields {
			.field:last-child {
				padding-right: 0 !important;
			}
		}

		&.field {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.ui {
			&.input {
				width: 70% !important;
			}
		}
	}
}
