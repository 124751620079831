.popup {
	width: 300px;
	max-width: 400px !important;
}

.row {
	align-items: center !important;

	.eleven {
		padding-right: 0 !important;
	}

	.footer {
		button {
			width: 48%;
		}
	}

	.arrayInput {
		input {
			width: 70px;
		}
	}

	.arrayWrapper {
		display: flex;
		justify-content: space-between;

		width: 100%;
	}
}
