.VehicelesSerch {
	position: relative;
	transition: all 300ms ease-in-out;

	.searchButton {
		width: 100%;

		&.isButtonVisible {
			display: none;
		}
	}

	.form {
		padding: 14px;
	}

	.iconButton {
		border-top-left-radius: 0;
		border-top-right-radius: 0.28571429rem !important;
		border-bottom-right-radius: 0.28571429rem !important;
		border-bottom-left-radius: 0;
	}
}
