.sideBar {
	position: relative;

	border: 1px solid rgba(34, 36, 38, 0.15);
	border-radius: 0.28571429rem;
	background: #FFF;
	box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);

	.searchInput {
		padding: 14px;
	}
}
