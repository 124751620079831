.dzu-dropzone {
	position: relative;

	display: flex;
	overflow: scroll;
	align-items: initial;
	flex-direction: column;

	box-sizing: border-box;
	width: 100%;
	min-height: 120px;
	margin: 0 auto;

	transition: all 0.15s linear;

	border: 2px solid #D9D9D9;
	border-radius: 4px;
}

.dzu-dropzoneActive {
	border-color: #2484FF;
	background-color: #DEEBFF;
}

.dzu-dropzoneDisabled {
	opacity: 0.5;
}

.dzu-dropzoneDisabled *:hover {
	cursor: unset;
}

.dzu-input {
	display: none;
}

.dzu-inputLabel {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	color: #2185D0;

	font-family: 'Helvetica', sans-serif;
	font-size: 20px;
	font-weight: 600;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

.dzu-inputLabelWithFiles {
	display: flex;
	align-items: center;
	align-self: flex-start;
	justify-content: center;

	min-height: 1em;
	margin-top: 20px;
	margin-left: 3%;
	padding: 0.78571429em 1.5em 0.78571429em;

	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
	text-align: center;
	vertical-align: baseline;
	text-decoration: none;
	text-transform: none;

	color: rgba(0, 0, 0, 0.6);
	border: none;
	border-radius: 0.28571429rem;
	outline: none;
	background: #E0E1E2 none;
	box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
	text-shadow: none;

	font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: bold;
	font-style: normal;
	line-height: 1em;
	will-change: '';
	-webkit-tap-highlight-color: transparent;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

.dzu-previewContainer {
	position: relative;
	z-index: 1;

	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;

	box-sizing: border-box;
	width: 100%;
	min-height: 60px;
	padding: 40px 3%;

	border-bottom: 1px solid #ECECEC;
}

.dzu-previewStatusContainer {
	display: flex;
	align-items: center;
}

.dzu-previewFileName {
	color: #333;

	font-family: 'Helvetica', sans-serif;
	font-size: 14px;
	font-weight: 400;
}

.dzu-previewImage {
	width: auto;
	max-width: 140px;
	max-height: 40px;

	border-radius: 4px;
}

.dzu-previewButton {
	width: 14px;
	height: 14px;
	margin: 0 0 2px 10px;

	cursor: pointer;

	opacity: 0.9;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 14px 14px;
}

.dzu-submitButtonContainer {
	z-index: 1;

	margin: 24px 0;
}

.dzu-submitButton {
	display: flex;
	align-items: center;
	align-self: flex-start;
	justify-content: center;

	min-height: 1em;
	margin-top: 20px;
	margin-left: 3%;
	padding: 0.78571429em 1.5em 0.78571429em;

	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
	text-align: center;
	vertical-align: baseline;
	text-decoration: none;
	text-transform: none;

	color: #FFF;
	border: none;
	border-radius: 0.28571429rem;
	outline: none;
	background: #2185D0 none;
	box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
	text-shadow: none;

	font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: bold;
	font-style: normal;
	line-height: 1em;
	will-change: '';
	-webkit-tap-highlight-color: transparent;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

.dzu-submitButton:disabled {
	cursor: unset;

	color: #333;
	background-color: #E6E6E6;
}

.filePreviewWrapper {
  display: flex;
  align-items: center;
}
