@import "./common/components/styles/agreement-form";
@import "./common/components/styles/clients";
@import "./common/components/styles/vehicles";
@import "./common/components/styles/tabs";
@import "./common/components/styles/image-viewer";
@import "./common/components/styles/planner";
@import "./common/components/MainMenu/MainMenu.module";
@import "./common/styles/animations.css";

body {
	background-color: #EEE !important;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
}

.App-header {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	min-height: 100vh;

	color: white;
	background-color: #282C34;

	font-size: calc(10px + 2vmin);
}

.App-link {
	color: #09D3AC;
}

h3 {
	text-transform: uppercase;
}

.section-name {
	background-color: #D5D5D5 !important;
}

.section-message {
	padding: 0 !important;
}

.top-nav-bar {
	.right-bar {
		text-align: right;
	}
}

.main-container {
	position: relative;
}

.auth-error-message {
	min-height: 25px;
}

.loader-overlay {
	position: absolute;
	z-index: 20;

	width: 100%;
	height: 100%;

	opacity: 0.1;
	background: #000;
}

.menu {
	.active {
		&.item {
			color: #FFF !important;
			background-color: #2185D0 !important;
		}
	}
}

.tool-bar {
	.item {
		button {
			margin-right: 0.25em !important;
		}

		&::before {
			display: none;
		}

		.selection {
			&.dropdown {
				margin: -0.5em 0;
			}
		}
	}
}

.calc-tool-bar {
	border: 0 !important;
	box-shadow: none !important;

	.item {
		&::before {
			display: none;
		}
	}
}

.user-list {
	a {
		&.item {
			position: relative;

			min-height: 43px;
			padding-top: 0.42857143em;

			&:last-child {
				padding-bottom: 5px !important;
			}

			.content {
				padding-top: 5px !important;
			}

			&.active {
				border-top-color: #FFF !important;
				background-color: #2185D0;

				i {
					&.icon {
						color: #FFF !important;
					}
				}

				.content {
					.header {
						color: #FFF;
					}

					.description {
						color: #FFF;
					}
				}
			}
		}
	}

	&.old-style {
		a {
			&.item {
				&.active {
					&::after {
						position: absolute;
						top: 0;
						right: -1em;

						visibility: inherit;

						width: 1em;
						height: 100%;

						content: '';

						background-color: #2185D0;
					}

					&::before {
						position: absolute;
						top: 0;
						left: -1em;

						visibility: inherit;

						width: 1em;
						height: 100%;

						content: '';

						background-color: #2185D0;
					}
				}
			}
		}
	}
}

.media-tab {
	padding-bottom: 30px !important;
}

.exp-tab {
	table {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
}

.ui.small.icon.message > .icon:not(.close) {
	font-size: 1.4em !important;
}

.modalOverlay {
	position: absolute;
	z-index: 10000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	background-color: rgba(0, 0, 0, 0.36);
}

.modalAgreement {
	position: absolute;
	top: 100px;
	right: 150px;
	left: 150px;

	width: 900px;
	max-width: 1000px;
	margin: 0 auto;

	background-color: #FFF;

	.segment-form {
		min-height: 420px;
	}

	.card {
		height: 100%;

		.card-header {
			background-color: #E4E4E4 !important;
		}
	}
}

.modalExpenses {
	position: absolute;
	top: 250px;
	right: 250px;
	left: 250px;

	background-color: #FFF;

	.card {
		height: 100%;

		.card-header {
			background-color: #E4E4E4 !important;
		}
	}
}

.expenses-table {
	td:nth-child(1) {
		min-width: 92px;
	}

	td:nth-child(2) {
		min-width: 70px;
	}

	td:nth-child(3) {
		min-width: 100px;
	}

	td {
		word-break: break-all;
	}
}

.cal-wrapper {
	flex: 1 1 auto;

	width: 100%;
	padding-right: 0.5em;
	padding-left: 0.5em;
}

.to_label {
	display: flex;
	align-items: center;

	padding-top: 20px;

	font-weight: bold;
}

.form-label {
	display: block;

	margin: 0 0 0.2rem 0;

	text-transform: none;

	color: rgba(0, 0, 0, 0.87);

	font-size: 0.92857143em;
	font-weight: bold;
}

// Agreement calendar

.ris {
	position: relative;

	.ris-is-clearable {
	}

	.ris-clearable {
		position: absolute;
		top: 50%;
		right: 5px;

		margin-top: -1px;
		padding: 5px;

		cursor: pointer;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);

		color: #555;

		font-size: 1.1em;
	}
}


/* Image load in form */
.load-image-wrapper {
	position: relative;

	min-height: 180px;

	.placeholder {
		position: relative;
		z-index: 2;

		min-height: 180px;
	}

	.image-wrapper {
		position: absolute;

		width: 100%;
		min-height: 180px;

		background-size: contain;

		img {
			z-index: -1;

			width: 100%;
			height: 100%;
			min-height: 180px;
			max-height: 250px;

			transition: all 0.2s linear;
		}
	}

	.image-load-buttons-wrapper {
		position: absolute;
		bottom: 10px;

		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;

		width: 100%;
		margin-top: 10px;

		transition: opacity 0.2s linear;

		opacity: 0;
	}

	&:hover {
		.image-load-buttons-wrapper {
			opacity: 1;
		}
	}

	.field {
		margin: 0 !important;
	}
}

/* tab form */
.tab-wrapper-inner {
	input {
		border: 2px solid #CCC9C9 !important;
	}
}

.input-disable {
	pointer-events: none;

	opacity: 0.5;
}

.form-group {
	transition: all 0.2s linear;
}

.form-group-disable {
	pointer-events: none;

	opacity: 0.7;

	.tab-wrapper-inner {
		input {
			color: #565656 !important;
			border: 2px solid #CCC9C9 !important;
		}
	}

	.selection {
		border-color: transparent !important;
	}

	.red.remove.link.icon {
		display: none;
	}

	.ui.icon.input {
		input {
			padding-right: 14px !important;
		}
	}
}

.button-group-error {
	background-color: #E0B4B4 !important;
}

.status-message {
	transition: opacity 300ms ease-in-out;

	opacity: 1;
}

.hidden-message {
	opacity: 0;
}

.popup {
	z-index: 12000 !important;
}

.Toastify__toast-container {
	z-index: 13000 !important;
}

.ui.page.modals {
	z-index: 13000 !important;
}

@media (max-height: 600px) {
	body {
		overflow: hidden;
	}

	.modalAgreement {
		top: 10px;
	}

	.modalOverlay {
		overflow-y: auto;
	}
}
